<template>
  <div class="jobs">
    <h1>Vtex Jobs</h1>
    <p>Acompanhe os jobs agendados na ferramenta</p>

    <div class="page-container" v-if="jobs.length > 0">
      <b-table striped hover :items="jobs" :fields="fields"></b-table>
      <!--<table>
            <thead>
                <tr>
                    <th>Item ID</th>
                    <th>Ref</th>
                    <th>Type</th>
                    <th>Feito</th>
                    <th>Data</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(job, i) in jobs" :key="i">
                    <td>{{job.id}}</td>
                    <td>{{job.itemId}}</td>
                    <td>{{job.type}}</td>
                    <td>{{(job.done === 1) ? 'Sim' : 'Não' }}</td>
                    <td>{{job.created_at | filterDataHora}}</td>
                </tr>
            </tbody>
        </table>-->
      <hr />
      <paginate
        v-model="page"
        :page-count="last_page"
        :click-handler="functionName"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination'"
      >
      </paginate>
    </div>

    <div class="page-container" v-else>
      <p>Nenhum resultado</p>
    </div>
  </div>
</template>

<script>
import { listarJobs } from "@/api/vtex/jobs";
import { filterDataHora } from "@/utils/date";
export default {
  data() {
    return {
      page: 1,
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "itemId",
          label: "ID Referência",
        },
        {
          key: "type",
          label: "Tipo",
        },
        {
          key: "done",
          formatter: (value) => {
            return value ? "Sim" : "Não";
          },
        },
        {
          key: "created_at",
          label: "Criado em",
          formatter: (value) => {
            return filterDataHora(value);
          },
        },
      ],
    };
  },
  filters: {
    filterDataHora,
  },
  computed: {
    jobs() {
      return this.$store.state.vtex.jobs;
    },
    last_page() {
      return this.$store.state.vtex.jobs_last_page;
    },
    per_page() {
      return this.$store.state.vtex.jobs_per_page;
    },
  },
  methods: {
    listarJobs,
    functionName(num) {
      this.page = num;
      this.listarJobs(this.page);
    },
  },
  mounted() {
    this.listarJobs(this.page);
  },
};
</script>

<style lang="scss" scoped>
.jobs {
  height: 100%;

  .page-container {
    margin: 30px 0;
  }
}
</style>
